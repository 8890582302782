import * as React from 'react'

import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import getConfig from 'next/config'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

import { useLazyQuery } from '@apollo/react-hooks'
import withHydrationOnDemand from 'react-hydration-on-demand'

import { NextConfig } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

import {
  Country,
  Currency,
  Feature,
  Maybe,
  Page,
  PageAlternateLink,
  Product,
  ProductList,
  ProductSort,
  QueryPageArgs,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  i18n,
  LIST_EXTENSION,
  PageWrapper,
  Routes,
  useHorizonFeature,
  useSessionSettings,
  useSiteConfig,
  useSiteDefinition,
  withCacheConfiguration,
  loggerProvider,
  getSafeUrlParametersFromWindow,
  EnterpriseNextPageContext,
  getFacetFilterUrl,
} from '@thg-commerce/enterprise-core'
import { FullHeaderLayout } from '@thg-commerce/enterprise-core/src/Layouts'
import { GridItem } from '@thg-commerce/gravity-system'
import {
  EnterpriseNextPage,
  PageType,
} from '@thg-commerce/enterprise-core/src/App/types'
import { getHistogram } from '@thg-commerce/enterprise-metrics/src/prometheus/histogram'
import { Resolvers } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers'
import {
  ComponentName,
  ComponentWidgetsVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'
import {
  generateObjectCacheKey,
  ObjectCacheKey,
  pageTTLConfig,
  PageType as CachePageType,
} from '@thg-commerce/enterprise-cache'

import { WidgetRenderer } from '@thg-commerce/enterprise-widgets'
import {
  DataLayerProducts,
  ProductListProductsArgs,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Page/ProductListProducts'
import { PageData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Page/Page'
import { getFacetInputFromUrl } from '@thg-commerce/enterprise-widgets/src/ProductListWidgetRenderer/utils'
import {
  ChangeType,
  PageOptions,
  ProductListPage,
} from '@thg-commerce/enterprise-widget-product-list-page'
import { getFacetUrlFromInput } from '@thg-commerce/enterprise-widget-product-list-page/src/Facets/utils'
import { ProductCategoryItemProps } from '@thg-commerce/enterprise-widget-product-categories'
import { BreadcrumbsRenderer } from '@thg-commerce/enterprise-components'
import { spacing, styled } from '@thg-commerce/enterprise-theme'
import { pushToEnhancedDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { LandingPageContextProvider } from '@thg-commerce/enterprise-pages'
import {
  EnterpriseRequest,
  EnterpriseResponse,
} from '@thg-commerce/enterprise-core/src/Server/types'
import { loadConfiguration } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/ConfigurationLoader'
import { PageCacheConfiguration } from '@thg-commerce/enterprise-core/src/cache/configuration'
import { SponsoredAdsType } from '@thg-commerce/enterprise-components/SponsoredAds/queryBuilders'
import { ThemeInterface } from '@thg-commerce/gravity-theme'
import { createApolloWithRequest } from '@thg-commerce/enterprise-core/src/Factory/apollo'
import { createGraphQLClient } from '@thg-commerce/enterprise-core/src/Factory/graphql'
import { PageArgs } from '@thg-commerce/enterprise-aurora-landing/resolvers/Page/resolver'

import { getPathAndParamsFromReq } from '../../src/utils'
import CustomError from '../_error'
import { Page as AURORA_PAGE_QUERY } from '../../graphql/Page.graphql'
import { Page as LIST_PAGE_QUERY } from '../../src/graphql/Page.graphql'
import { GlobalComponentWidgets as GLOBAL_COMPONENT_QUERY_AURORA } from '../../graphql/GlobalComponentWidgets.graphql'
import { ConcessionCode as CONCESSION_CODE_QUERY } from '../../src/graphql/ConcessionCode.graphql'
import { checkForPrefixedPageMatch } from '../../src/helpers/page'
// @TODO: [CSBOM-325] Pull these values from content keys
const IFP_FILTERS = [
  'en_brand_content',
  'en_hbg_sizeFacet_content',
  'en_hbg_baseColour_content',
  'en_hbg_type_content',
]

const GlobalWidgetRendererComponent = withHydrationOnDemand({
  on: [['delay', 500]],
})(WidgetRenderer)

export interface LandingPageProps {
  path: string
  page?: PageData
  componentWidgets?: Maybe<Widget[]>
  productList?: any
  dataLayerProducts?: DataLayerProducts
  pageParams: {
    pageNumber?: string
    facetFilters?: string
    sortOrder?: string
  }
  statusCode: number
  showBackwardNavigationForBreadcrumbs: boolean
  wishlistEnabled?: boolean
  theme?: ThemeInterface
  pageType?: PageType
}

const createRobotsContent = (noIndex: boolean, noFollow: boolean) => {
  return `${noIndex ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`
}

export const BreadcrumbsRendererWrapper = styled(BreadcrumbsRenderer)`
  max-width: ${(props) => props.theme.site.siteWidth};
  padding-bottom: ${spacing(2)};
`

const Landing: EnterpriseNextPage<LandingPageProps> = (
  props: LandingPageProps,
) => {
  const [productList, setProductList] = React.useState(props.productList)
  const i18nText = {
    metaTagKeywords: i18n('meta.keywords.end.text'),
    siteTitleEnd: i18n('titles.end.text'),
    defaultMetaDescriptionPart1: i18n('meta.description.list.default.1'),
    defaultMetaDescriptionPart2: i18n('meta.description.list.default.2'),
    defaultMetaDescriptionPart3: i18n('meta.description.list.default.3'),
  }
  const { siteName, originUrl, defaultSessionSettings } = useSiteDefinition()
  const {
    enhancedEcommerceEnabled,
    enableIndexableFacetsPagination,
    hasImagesCarouselOnMobilePLP,
    enableVipPrice,
  } = useSiteConfig()
  const sessionSettings = useSessionSettings()

  const vipPriceEnabledHorizon = useHorizonFeature(Feature.VipPricingEnabled)
  const clickAndCollectEnabled = useHorizonFeature(Feature.ClickAndCollect)

  const vipPriceEnabled = enableVipPrice && vipPriceEnabledHorizon

  const [getPage, { loading, error, data }] = useLazyQuery<
    {
      page: Page
      componentWidgets: Maybe<Widget[]>
      productListProducts?: {
        description: string | null
        seoDescription: string | null
        page: Page
      }
    },
    QueryPageArgs & ComponentWidgetsVariables & ProductListProductsArgs
  >(LIST_PAGE_QUERY)

  React.useEffect(() => {
    if (data) {
      const productList = (data?.productListProducts?.page?.widgets
        ?.filter((widget) => widget['__typename'] === 'ProductListWidget')
        .pop() as unknown) as {
        description: string | null
        seoDescription: string | null
        products: ProductList
      }

      const products: Product[] =
        (productList?.products && productList?.products.products) || []

      const topProductCategorySet = data.page?.widgets?.find(
        (item) => item['__typename'] === 'TopProductCategorySet',
      ) as any

      const productCategoryItems: ProductCategoryItemProps[] =
        topProductCategorySet?.banners
          ?.filter(
            (item) => item.topProductCategoryName && item.topProductCategoryUrl,
          )
          .map((item) => {
            return {
              text: item.topProductCategoryName as string,
              href: item.topProductCategoryUrl as string,
            }
          }) || []

      const url = new URL(window.location.href)
      setProductList({
        // TODO: conditionally merge when products exist
        products,
        itemsPerPage: props.productList?.itemsPerPage,
        title: props.productList?.title || '',
        description: productList.description || '',
        seoDescription: productList.seoDescription || '',
        changeType: ChangeType.NONE,
        categories: productCategoryItems,
        categoriesTitle: topProductCategorySet?.DiscoverTitle || null,
        facets: productList?.products?.facets,
        pageOptions: {
          sort: props.productList?.pageOptions?.sort || ProductSort.Relevance,
          facets: getFacetInputFromUrl(
            url.searchParams.get('facetFilters') || '',
          ),
          pageNumber:
            getSafeUrlParametersFromWindow<{ pageNumber: string }>(
              window,
              'pageNumber',
            ).pageNumber || 1,
        },
        totalItems: productList?.products?.total || products.length || 0,
      })
    }
  }, [loading, data, error])

  React.useEffect(() => {
    if (enhancedEcommerceEnabled && props.productList) {
      pushToEnhancedDataLayer({
        event: 'view_item_list',
        ecommerce: {
          list: {
            products: props.dataLayerProducts,
          },
        },
      })
    }
  }, [])

  if (!props.page || props.statusCode === 404) {
    return <CustomError statusCode={props.statusCode || 404} />
  }

  const breadcrumbs = props.page.breadcrumbs.map((item) => {
    return {
      text: item.displayName,
      url:
        item.pagePath === '/'
          ? item.pagePath
          : item.pagePath.concat(LIST_EXTENSION),
    }
  })

  const breadcrumbSchemaItems = breadcrumbs.map((breadcrumb, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.text,
      item: originUrl.concat(breadcrumb.url),
    }
  })
  const sessionStorage = typeof window !== 'undefined' && window.sessionStorage

  let facetsInTitle = ''
  if (enableIndexableFacetsPagination && productList) {
    facetsInTitle = productList?.pageOptions?.facets
      .map((facet) =>
        IFP_FILTERS.includes(facet.facetName) && facet.selections.length === 1
          ? facet.selections[0].optionName
          : '',
      )
      .join(' ')
  }

  const generateIFPCanonicalTag = (url: URL) => {
    if (
      !productList?.pageOptions?.facets.some(
        (item) => item.selections.length > 1,
      )
    ) {
      return getFacetFilterUrl(url.toString())
    }
    return ''
  }

  const generateDefaultCanonicalTag = (url: URL) => {
    const baseCanonicalTag = props.page?.canonicalUrl
      ? `${originUrl}${props.page?.canonicalUrl}`
      : url.toString().split('?')[0]
    const basePagination = props.pageParams?.pageNumber
      ? `?pageNumber=${props.pageParams.pageNumber}`
      : ''
    return `${baseCanonicalTag}${basePagination}`
  }

  const isIFPCompatiblePLP = (url: URL) => {
    return (
      props.pageType === PageType.PRODUCT_LIST &&
      enableIndexableFacetsPagination &&
      url.toString().includes('IFP=true')
    )
  }

  const generateCanonicalTag = () => {
    if (typeof window === 'undefined') {
      return ''
    }
    const url = new URL(window.location.href)

    if (!isIFPCompatiblePLP(url)) {
      return generateDefaultCanonicalTag(url)
    } else {
      return generateIFPCanonicalTag(url)
    }
  }

  const canonicalTag = generateCanonicalTag()

  return (
    <React.Fragment>
      <Head>
        <title>
          {(enableIndexableFacetsPagination && facetsInTitle
            ? `${facetsInTitle} ${productList.title} | ${props.page?.title}`
            : props.page?.title) || i18nText.siteTitleEnd}
        </title>
        {props.path === '/' && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: originUrl,
                logo: props?.theme?.logo?.logoUri,
              }),
            }}
          ></script>
        )}
        <meta
          name="description"
          content={
            props.page?.metaDescription ||
            `${i18nText.defaultMetaDescriptionPart1} ${props.page?.title} ${i18nText.defaultMetaDescriptionPart2} ${i18nText.defaultMetaDescriptionPart3}`
          }
        />
        {
          <meta
            name="robots"
            content={createRobotsContent(
              props.page.noIndex,
              props.page.noFollow,
            )}
          />
        }
        <meta
          name="keywords"
          content={`${siteName}, ${i18nText.metaTagKeywords}`}
        />
        {props.page?.alternateLinks.map((link: PageAlternateLink) => (
          <link
            key={link.locale}
            rel="alternate"
            href={`https://${link.pagePath}${
              props.path !== '/' ? LIST_EXTENSION : ''
            }`}
            hrefLang={link.locale}
          />
        ))}

        {canonicalTag && (
          <link rel="canonical" href={canonicalTag} key="link_canonical" />
        )}

        {props.path === '/' && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url: originUrl,
                potentialAction: {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `${originUrl}/elysium.search?search={search_term_string}`,
                  },
                  'query-input': 'required name=search_term_string',
                },
              }),
            }}
          />
        )}
      </Head>
      <LandingPageContextProvider categories={props.page.categories}>
        <PageWrapper enableMaxWidth={false} compactMargin={true}>
          <GridItem colSpan={12}>
            {props.path !== '/' && (
              <BreadcrumbsRendererWrapper
                breadcrumbs={breadcrumbs}
                schemaItems={breadcrumbSchemaItems}
                enableBackButton={props.showBackwardNavigationForBreadcrumbs}
                originUrl={originUrl}
              />
            )}
            <WidgetRenderer
              widgets={props.page.widgets || []}
              path={props.path}
              pageParams={props.pageParams}
            />
            {props.productList && (
              <ProductListPage
                path={props.path}
                sponsoredAdsType={SponsoredAdsType.PLP}
                dataLayerProducts={props.dataLayerProducts}
                setSingleColumn={(item, value) =>
                  sessionStorage && sessionStorage.setItem(item, value)
                }
                {...productList}
                loading={loading}
                facetsInTitle={facetsInTitle}
                sortTypes={[
                  {
                    type: ProductSort.Relevance,
                    displayText: i18n('product.list.sort.relevance'),
                  },
                  {
                    type: ProductSort.Popularity,
                    displayText: i18n('product.list.sort.popularity'),
                  },
                  {
                    type: ProductSort.PriceLowToHigh,
                    displayText: i18n('product.list.sort.pricelowtohigh'),
                  },
                  {
                    type: ProductSort.PriceHighToLow,
                    displayText: i18n('product.list.sort.pricehightolow'),
                  },
                  {
                    type: ProductSort.NameAlphabetically,
                    displayText: i18n('product.list.sort.namealphabetically'),
                  },
                  {
                    type: ProductSort.NewestToOldest,
                    displayText: i18n('product.list.sort.newesttooldest'),
                  },
                  {
                    type: ProductSort.DiscountPercentageHighToLow,
                    displayText: i18n(
                      'product.list.sort.discountpercentagehightolow',
                    ),
                  },
                ]}
                onInputChange={(newInput: PageOptions) => {
                  const url = new URL(window.location.href)
                  url.hash = 'ProductList'
                  if (newInput.pageNumber) {
                    newInput.pageNumber > 1
                      ? url.searchParams.set(
                          'pageNumber',
                          newInput.pageNumber.toString(),
                        )
                      : url.searchParams.delete('pageNumber')
                  }
                  if (newInput.sort) {
                    url.searchParams.set('sortOrder', newInput.sort.toString())
                    url.searchParams.delete('pageNumber')
                  }

                  if (newInput.facets) {
                    url.searchParams.set(
                      'facetFilters',
                      getFacetUrlFromInput(newInput.facets),
                    )
                    url.searchParams.delete('pageNumber')
                    if (enableIndexableFacetsPagination) {
                      if (
                        !newInput.facets.length &&
                        url.searchParams.has('IFP')
                      ) {
                        url.searchParams.delete('IFP')
                      } else {
                        const setIFPfilter = IFP_FILTERS.some((filter) =>
                          url.search.includes(filter),
                        )
                        setIFPfilter
                          ? url.searchParams.set('IFP', 'true')
                          : url.searchParams.set('IFP', 'false')
                      }
                    }
                    window.history.replaceState(
                      {},
                      document.title,
                      url.toString(),
                    )
                    getPage({
                      variables: {
                        clickAndCollectEnabled,
                        vipPriceEnabled,
                        path: props.path,
                        name: ComponentName.GLOBAL,
                        wishlistEnabled: props.wishlistEnabled,
                        limitImages: hasImagesCarouselOnMobilePLP ? 10 : 2,
                        input: {
                          currency:
                            sessionSettings?.currency ||
                            defaultSessionSettings.currency,
                          shippingDestination:
                            sessionSettings?.shippingDestination ||
                            defaultSessionSettings.shippingDestination,
                          limit: props.productList?.itemsPerPage,
                          offset: 0, // reset offset as we're updating facets
                          sort:
                            (url.searchParams.get(
                              'sortOrder',
                            ) as ProductSort) || ProductSort.Relevance,
                          facets: getFacetInputFromUrl(
                            url.searchParams.get('facetFilters') || '',
                          ),
                        },
                        currency:
                          sessionSettings?.currency ||
                          defaultSessionSettings.currency,
                        shippingDestination:
                          sessionSettings?.shippingDestination ||
                          defaultSessionSettings.shippingDestination,
                      },
                    })
                  } else {
                    window.location.href = url.toString()
                  }
                }}
              />
            )}
          </GridItem>
        </PageWrapper>
        <GlobalWidgetRendererComponent
          widgets={props.componentWidgets || []}
          path={props.path}
          pageParams={props.pageParams}
        />
      </LandingPageContextProvider>
    </React.Fragment>
  )
}

Landing.Layout = FullHeaderLayout

Landing.supportsConcessions = true

Landing.pageType = (route, componentProps) => {
  if (route === Routes.HomePage) {
    return PageType.HOME_PAGE
  }

  if (componentProps.productList) {
    return PageType.PRODUCT_LIST
  }

  return PageType.LANDING
}

Landing.getConcessionCode = async (
  ctx: EnterpriseNextPageContext,
  apolloClient?: ApolloClient<NormalizedCacheObject>,
): Promise<string | undefined> => {
  const horizonFeatures = ctx.req.horizonFeatures || []

  if (!horizonFeatures?.includes(Feature.Concessions)) {
    return undefined
  }

  const concessionEnabled = horizonFeatures?.includes(Feature.Concessions)

  if (!concessionEnabled) {
    return undefined
  }

  const { path } = getPathAndParamsFromReq(ctx)

  if (!path || path === '') {
    return undefined
  }

  if (apolloClient) {
    const { data } = await apolloClient.query<{
      page: {
        concession: {
          concessionCode: string
        }
      }
    }>({
      query: CONCESSION_CODE_QUERY,
      variables: {
        pathName: path,
      },
    })

    if (data?.page?.concession?.concessionCode) {
      return data.page.concession.concessionCode
    }

    return undefined
  }

  return undefined
}

export const getServerSideProps = withCacheConfiguration<
  { props: LandingPageProps },
  GetServerSidePropsContext
>(
  async (
    context: GetServerSidePropsContext,
  ): Promise<{ props: LandingPageProps; cache?: PageCacheConfiguration }> => {
    const nextConfig = getConfig() as NextConfig
    const request = context.req as EnterpriseRequest
    const response = context.res as EnterpriseResponse
    const config = await loadConfiguration(nextConfig, request)

    const { publicRuntimeConfig } = config
    const { brand, subsite, originUrl } = publicRuntimeConfig.siteDefinition
    const logger = loggerProvider({ brand, subsite, originUrl })
    const { siteDefinition, siteConfig } = publicRuntimeConfig

    const results = getPathAndParamsFromReq(context)

    const shippingDestination = (request.config.sessionSettings
      ?.shippingDestination ||
      siteDefinition.defaultSessionSettings.shippingDestination) as Country
    const currency = (request.config.sessionSettings?.currency ||
      siteDefinition.defaultSessionSettings.currency) as Currency

    const {
      productItemsPerPage,
      showBackwardNavigationForBreadcrumbs = false,
      enableWishlists,
      enableWishlistsGlobal,
      hideProductListWishlists,
      hasImagesCarouselOnMobilePLP,
      enableHeroProductImageTag,
    } = siteConfig
    const horizonFeatures = request.horizonFeatures
    const clickAndCollectEnabled = horizonFeatures?.includes(
      Feature.ClickAndCollect,
    )
    const vipPriceEnabled =
      (siteConfig.enableVipPrice ?? false) &&
      (request.horizonFeatures || []).includes(Feature.VipPricingEnabled)
    const wishlistEnabled =
      (enableWishlists &&
        enableWishlistsGlobal &&
        !hideProductListWishlists &&
        horizonFeatures?.includes(Feature.Wishlist)) ||
      false

    const imageLimit = hasImagesCarouselOnMobilePLP ? 10 : 2

    const itemsPerPage = productItemsPerPage || 45
    const selectedPageNumber =
      results.pageParams?.pageNumber &&
      parseInt(results.pageParams?.pageNumber, 10) >= 1
        ? parseInt(results.pageParams?.pageNumber, 10)
        : 1
    const sortOrder =
      (results?.pageParams?.sortOrder as ProductSort) || ProductSort.Relevance

    const path =
      results.path === ''
        ? '/'
        : new URL(
            decodeURIComponent(results.path),
            siteDefinition.originUrl,
          ).pathname
            .replace(/\./g, '')
            .replace(/^\/perf\//, '') // @TODO: Improve handling of URL's that contain dots - Horizon throws an exception

    const invalidPath = results.path !== '' && /[./]$/.test(results.path)

    const client = createGraphQLClient(config, request)

    const queryStartTime = new Date()
    let queryEndTime
    try {
      const enableAurora =
        process.env.ENABLE_AURORA === 'true' &&
        (siteConfig.enableAuroraLanding ||
          request.headers['x-enterprise-enable-aurora'] === '1')
      const productContentKeys = config.productBlockContentKeys

      if (enableAurora) {
        const { data } = await client.query<
          {
            page: PageData
            componentWidgets: Maybe<Widget[]>
            productListProducts?: { page: Page }
            rayId: string
          },
          PageArgs
        >(AURORA_PAGE_QUERY, {
          path,
          wishlistEnabled,
          currency,
          shippingDestination,
          productContentKeys,
          clickAndCollectEnabled: clickAndCollectEnabled ?? false,
          vipPriceEnabled,
          enableHeroProductImageTag: enableHeroProductImageTag || false,
          limitImages: imageLimit,
          productListInput: {
            currency,
            shippingDestination,
            limit: itemsPerPage,
            offset:
              selectedPageNumber > 1
                ? itemsPerPage * (selectedPageNumber - 1)
                : 0,
            sort: sortOrder,
            facets: getFacetInputFromUrl(
              results.pageParams.facetFilters
                ? decodeURIComponent(results.pageParams.facetFilters)
                : '',
            ),
          },
          context: {
            currency,
            shippingDestination,
            clickAndCollectEnabled,
            pagePath: path,
          },
        })

        queryEndTime = new Date().getTime() - queryStartTime.getTime()

        if (data?.page) {
          const productList = data.page.productListWidget
          const topProductCategorySet = productList?.topProductCategorySet

          const productCategoryItems: ProductCategoryItemProps[] =
            topProductCategorySet?.banners
              ?.filter(
                (item) =>
                  item.topProductCategoryName && item.topProductCategoryUrl,
              )
              .map((item) => {
                return {
                  text: item.topProductCategoryName as string,
                  href: item.topProductCategoryUrl as string,
                }
              }) || []

          const { data: auroraComponentWidgets } = await client.query<any>(
            GLOBAL_COMPONENT_QUERY_AURORA,
          )
          const cachePageType =
            path === '/' ? CachePageType.HOME : CachePageType.LANDING
          const cacheTTLConfig = pageTTLConfig(cachePageType) || {
            ttl: 300,
            grace: 86400,
          }
          const props = productList
            ? {
                ...results,
                rayId: data.page.rayId || null,
                wishlistEnabled,
                path,
                showBackwardNavigationForBreadcrumbs,
                componentWidgets:
                  auroraComponentWidgets?.globalComponentWidgets || null,
                page: data.page,
                pageType: PageType.PRODUCT_LIST,
                dataLayerProducts: productList.dataLayerProducts,
                productList: {
                  // TODO: conditionally merge when products exist
                  products: productList.productBlockList.productBlocks,
                  itemsPerPage,
                  title: productList.title || '',
                  description: productList.description || '',
                  seoDescription: productList.seoDescription || '',
                  changeType: ChangeType.NONE,
                  categories: productCategoryItems,
                  categoriesTitle: topProductCategorySet?.DiscoverTitle || null,
                  facets: productList.productBlockList?.facets,
                  pageOptions: {
                    sort: sortOrder,
                    facets: getFacetInputFromUrl(
                      results.pageParams.facetFilters
                        ? decodeURIComponent(results.pageParams.facetFilters)
                        : '',
                    ),
                    pageNumber: results.pageParams.pageNumber
                      ? parseInt(results.pageParams.pageNumber, 10)
                      : 1,
                  },
                  totalItems: productList?.productBlockList?.total || 0,
                  statusCode: invalidPath ? 404 : 200,
                },
                statusCode: invalidPath ? 404 : 200,
              }
            : {
                ...results,
                rayId: data.page.rayId || null,
                wishlistEnabled,
                path,
                showBackwardNavigationForBreadcrumbs,
                componentWidgets:
                  auroraComponentWidgets?.globalComponentWidgets || null,
                page: data.page,
                pageType: path === '/' ? PageType.HOME_PAGE : PageType.LANDING,
                statusCode: invalidPath ? 404 : 200,
              }
          return {
            props,
            cache: {
              ...cacheTTLConfig,
              vary:
                data.page.varyHeaders.concat(
                  data.productListProducts?.page.varyHeaders || [],
                ) || [],
              tags: [
                generateObjectCacheKey(ObjectCacheKey.PAGE, data.page.path),
              ],
            },
          }
        }
      } else {
        const allowOverrides =
          request.headers['x-enterprise-allow-overrides'] === '1'
        const allowSitePropertyOverrides =
          request.headers['x-enterprise-allow-site-property-overrides'] === '1'

        const productContentKeys = config.productBlockContentKeys
        const apollo = createApolloWithRequest(request, config, true)
        apollo.addResolvers(
          Resolvers(
            apollo,
            publicRuntimeConfig,
            allowOverrides,
            allowSitePropertyOverrides,
            request.config.enableVary,
            productContentKeys,
          ),
        )

        const { data } = await apollo.query<
          {
            page: PageData
            componentWidgets: Maybe<Widget[]>
            productListProducts?: { page: Page }
            rayId: string
          },
          QueryPageArgs & ComponentWidgetsVariables & ProductListProductsArgs
        >({
          query: LIST_PAGE_QUERY,
          variables: {
            path,
            wishlistEnabled,
            vipPriceEnabled,
            currency,
            shippingDestination,
            clickAndCollectEnabled,
            productContentKeys,
            limitImages: imageLimit,
            enableHeroProductImageTag: enableHeroProductImageTag || false,
            name: ComponentName.GLOBAL,
            input: {
              currency,
              shippingDestination,
              limit: itemsPerPage,
              offset:
                selectedPageNumber > 1
                  ? itemsPerPage * (selectedPageNumber - 1)
                  : 0,
              sort: sortOrder,
              facets: getFacetInputFromUrl(
                results.pageParams.facetFilters
                  ? decodeURIComponent(results.pageParams.facetFilters)
                  : '',
              ),
            },
          },
          context: {
            currency,
            shippingDestination,
            clickAndCollectEnabled,
            pagePath: path,
          },
        })
        queryEndTime = new Date().getTime() - queryStartTime.getTime()

        const widgets = data?.page?.widgets?.filter(
          (widget) => widget['__typename'] !== 'ProductListWidget',
        )

        const productList = (data?.productListProducts?.page?.widgets
          ?.filter((widget) => widget['__typename'] === 'ProductListWidget')
          .pop() as unknown) as {
          description: string | null
          seoDescription: string | null
          title: string | null
          products: ProductList
          dataLayerProducts: DataLayerProducts
        }

        const topProductCategorySet = data?.page?.widgets?.find(
          (item) => item['__typename'] === 'TopProductCategorySet',
        ) as any

        const products: Product[] =
          (productList?.products && productList?.products.products) || []

        const productCategoryItems: ProductCategoryItemProps[] =
          topProductCategorySet?.banners
            ?.filter(
              (item) =>
                item.topProductCategoryName && item.topProductCategoryUrl,
            )
            .map((item) => {
              return {
                text: item.topProductCategoryName as string,
                href: item.topProductCategoryUrl as string,
              }
            }) || []

        if (data?.page) {
          const cachePageType =
            path === '/' ? CachePageType.HOME : CachePageType.LANDING
          const cacheTTLConfig = pageTTLConfig(cachePageType) || {
            ttl: 300,
            grace: 86400,
          }

          const props = productList
            ? {
                ...results,
                rayId: data.page.rayId || null,
                wishlistEnabled,
                path,
                showBackwardNavigationForBreadcrumbs,
                componentWidgets: data.componentWidgets,
                page: { ...data.page, widgets },
                pageType: PageType.PRODUCT_LIST,
                dataLayerProducts: productList.dataLayerProducts,
                productList: {
                  // TODO: conditionally merge when products exist
                  products,
                  itemsPerPage,
                  title: productList?.title || '',
                  description: productList?.description || '',
                  seoDescription: productList?.seoDescription || '',
                  changeType: ChangeType.NONE,
                  categories: productCategoryItems,
                  categoriesTitle: topProductCategorySet?.DiscoverTitle || null,
                  facets: productList?.products?.facets,
                  pageOptions: {
                    sort: sortOrder,
                    facets: getFacetInputFromUrl(
                      results.pageParams.facetFilters
                        ? decodeURIComponent(results.pageParams.facetFilters)
                        : '',
                    ),
                    pageNumber: results.pageParams.pageNumber
                      ? parseInt(results.pageParams.pageNumber, 10)
                      : 1,
                  },
                  totalItems:
                    productList?.products?.total || products?.length || 0,
                  statusCode: invalidPath ? 404 : 200,
                },
                statusCode: invalidPath ? 404 : 200,
              }
            : {
                ...results,
                rayId: data.page.rayId || null,
                wishlistEnabled,
                path,
                showBackwardNavigationForBreadcrumbs,
                componentWidgets: data.componentWidgets,
                page: { ...data.page, widgets },
                pageType: path === '/' ? PageType.HOME_PAGE : PageType.LANDING,
                statusCode: invalidPath ? 404 : 200,
              }

          return {
            props,
            cache: {
              ...cacheTTLConfig,
              vary:
                data.page.varyHeaders.concat(
                  data.productListProducts?.page.varyHeaders || [],
                ) || [],
              tags: [
                generateObjectCacheKey(ObjectCacheKey.PAGE, data.page.path),
              ],
            },
          }
        }
      }
    } catch (e) {
      const error = e as Error
      logger.warn(`Failed to load list page with error ${error.message}`, {
        stack: error.stack,
      })
    } finally {
      try {
        const histogram = getHistogram({
          name: 'enterprise_monitor_api_timings',
          labels: ['appname', 'brand', 'subsite', 'endpoint'],
          help: 'This metric stores the duration of API calls',
        })
        histogram
          .labels('content', brand, subsite, 'content_page_query')
          .observe(queryEndTime)
      } catch (error) {
        console.warn(`failed to raise metric for page query: ${error}`)
      }
    }

    if (response) {
      const apollo = createApolloWithRequest(request, config, true)
      const prefixedPageMatch = await checkForPrefixedPageMatch(path, apollo)
      if (prefixedPageMatch) {
        const redirectURL = new URL(
          `${prefixedPageMatch}.list`,
          `https://${siteDefinition.domain}`,
        )
        Object.entries(request.query).forEach(([key, value]) => {
          if (typeof value === 'string') {
            const decodedValue = decodeURIComponent(value)
            redirectURL.searchParams.set(key, decodedValue)
          }
        })

        // Support proper UTF encoding for consistency
        redirectURL.search = redirectURL.searchParams
          .toString()
          .replace(/\+/g, '%20')

        response.redirect(301, redirectURL.toString())
      } else {
        response.statusCode = 404
      }
    }

    return {
      props: {
        path,
        showBackwardNavigationForBreadcrumbs,
        componentWidgets: [],
        pageParams: {
          ...(sortOrder && { sortOrder }),
        },
        statusCode: invalidPath ? 404 : response.statusCode || 404,
      },
    }
  },
)

export default Landing
